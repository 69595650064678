import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    generateStencil(assetCode) {
        const url = `${this.baseUrl}/generateStencilForm`;
        return axios.post(url, {
            params: {
                start: assetCode
            }
        });
    }
}