<template>
	<div class="animated fadeIn">
		<b-card title="Stencil Generator" sub-title="Automate stencil generation">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-card-text class="mt-4">
				<b-row>
					<b-col sm="6" md="3" class="mb-2">
						<b-dropdown text=" Select Actions " variant="dark">
							<b-dropdown-item @click="generateStencil">
								<i class="fa fa-plus"></i>&nbsp; Create New Stencil
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="3" offset-md="6" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table :items="items" :fields="fields" :responsive="true" selected-variant="primary"
					:current-page="currentPage" :per-page="perPage" show-empty>
					<template v-slot:cell(dateCreated)="row">{{
						row.item.dateCreated | toDateTimeString
					}}</template>
					<template v-slot:cell(actions)="row">
						<b-button size="sm" variant="info" class="mr-1" @click="onGenerateFile(row.item.startAssetCode)">
							Generate File
						</b-button>
					</template>
				</b-table>
			</b-card-text>

			<template v-slot:footer>
				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ items.length }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</template>
		</b-card>

		<StencilConfirmation />
	</div>
</template>

<script>
// Component
import StencilConfirmation from './stencilGenerator/StencilConfirmation';

// Util
import { generateExcelFile } from '@/utils/stencilUtil';

// API & Database
import api from '@/api/stencilApi';
import stencilDAO from '@/database/stencils';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import EventBus from '@/shared/event-bus';

export default {
	name: 'stencil-generator',
	components: {
		StencilConfirmation,
		JsonExcel,
		Loading,
	},
	data() {
		return {
			eventBus: EventBus,
			isLoading: false,
			items: [],
			fields: [
				{ key: 'startAssetCode', label: 'Start Asset Code' },
				{ key: 'lastAssetCode', label: 'Last Asset Code' },
				{ key: 'purpose', label: 'Purpose' },
				{ key: 'dateCreated', label: 'Date Created' },
				{ key: 'createdBy', label: 'Created By' },
				{ key: 'actions', label: 'Actions' },
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filterBy: {},
		};
	},
	mounted() {
		this.retrieveStencils();
		EventBus.$on('onGenerationSuccess', () => this.retrieveStencils());
		EventBus.$on('onCreateSuccess', () => this.retrieveStencils());
	},
	methods: {
		async retrieveStencils() {
			// show loading indicator
			this.isLoading = true;

			try {
				const stencilsObj = await stencilDAO.retrieve(this.filterBy);
				this.items = Object.values(stencilsObj);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onFilterRequest() {
			this.retrieveStencils();
		},
		resetFilters() {
			this.retrieveStencils();
		},
		async generateStencil() {
			EventBus.$emit('showStencilConfirmationDialog');
		},
		async onGenerateFile(assetCode) {
			this.$toaster.success('Stencil Form is now generating...');
			const { data } = await api.generateStencil(assetCode);
			await generateExcelFile(data);
		},
	},
	beforeDestroy() {
		EventBus.$off('onGenerationSuccess');
	},
};
</script>